import { makeStyles } from '@material-ui/core/styles';
import { neutral, brand, urgent } from '../../styles/color';

export const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'start',
    justifyContent: 'end',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  borderedInput: {
    border: `1px solid ${brand[400]}`,
    borderRadius: '16px',
    padding: '12px 16px',
    color: neutral[100],
    position: 'relative',
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  leftGroup: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rightGroup: {
    margin: '-8px',
  },
  fullWidth: {
    flexDirection: 'column',
  },
  stickyBottom: {
    position: 'sticky',
    bottom: '16px',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '16px',
  },
  imageContainer: {
    position: 'relative',
    width: '64px',
    height: '64px',
    marginTop: '8px',
    marginBottom: '4px',
    '&:hover $closeButton': {
      display: 'flex',
    },
  },
  imageThumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    border: `2px solid ${brand[400]}`,
    objectFit: 'cover',
  },
  closeButton: {
    display: 'none',
    position: 'absolute',
    top: '16px',
    right: '16px',
    background: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '18px',
    zIndex: 2,
    borderRadius: '16px',
  },
  error: {
    border: `1px solid ${urgent[400]}`,
  },
  errorMessage: {
    color: urgent[400],
    fontSize: '12px',
    marginTop: '4px',
  },
  lightBlueIcon: {
    '& svg': {
      color: `${brand[600]} !important`,
    },
  },
  darkBlueIcon: {
    '& svg': {
      color: `${brand[400]} !important`,
    },
  },
  inputBaseInput: {
    fontSize: '16px !important',
  },
  tooltip: {
    fontSize: '10px !important',
  },
}));
